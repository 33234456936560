import store from '@/store'
import axios from '@/plugins/axios'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_ACCOUNT;

class Account {
    listar_organizaciones(options) {
        return axios.get(utils.url_options(baseURL + '/organizaciones', options));
    }

    crear_organizacion(payload) {
        return axios.post(baseURL + '/organizaciones', payload);
    }

    editar_organizacion(id, payload) {
        return axios.put(baseURL + '/organizaciones/' + id, payload);
    }

    eliminar_organizacion(id) {
        return axios.delete(baseURL + '/organizaciones/' + id);
    }

    listar_aplicaciones(options) {
        return axios.get(utils.url_options(baseURL + '/aplicaciones', options));
    }

    crear_aplicacion(payload) {
        return axios.post(baseURL + '/aplicaciones', payload);
    }

    editar_aplicacion(id, payload) {
        return axios.put(baseURL + '/aplicaciones/' + id, payload);
    }

    eliminar_aplicacion(id) {
        return axios.delete(baseURL + '/aplicaciones/' + id);
    }

    listar_roles(options) {
        return axios.get(utils.url_options(baseURL + '/roles', options));
    }

    crear_roles(payload) {
        return axios.post(baseURL + '/roles', payload);
    }

    editar_roles(id, payload) {
        return axios.put(baseURL + '/roles/' + id, payload);
    }

    eliminar_roles(id) {
        return axios.delete(baseURL + '/roles/' + id);
    }

    listar_app_permisos(idApp) {
        return axios.get(baseURL + '/aplicaciones/' + idApp + '/permisos');
    }

    crear_permiso(idApp, payload) {
        return axios.post(baseURL + '/aplicaciones/' + idApp + '/permisos', payload);
    }

    eliminar_permisos(idApp, payload) {
        return axios.delete(baseURL + '/aplicaciones/' + idApp + '/permisos', { data: payload });
    }

    listar_permisos(idRol) {
        return axios.get(baseURL + '/roles/' + idRol + '/permisos');
    }

    asociar_permiso(idRol, payload) {
        return axios.post(baseURL + '/roles/' + idRol + '/permisos', payload);
    }

    desasociar_permiso(idRol, idPer) {
        return axios.delete(baseURL + '/roles/' + idRol + '/permisos/' + idPer);
    }

    listar_rol_roles(idRol) {
        return axios.get(baseURL + '/roles/' + idRol + '/hijos');
    }

    asociar_rol(idRol, payload) {
        return axios.post(baseURL + '/roles/' + idRol + '/agrupaciones', payload);
    }

    desasociar_rol(idRol, payload) {
        return axios.delete(baseURL + '/roles/' + idRol + '/agrupaciones', { data: payload });
    }

    listar_usuarios(options) {
        return axios.get(utils.url_options(baseURL + '/usuarios', options));
    }

    crear_usuario(payload) {
        return axios.post(baseURL + '/usuarios', payload);
    }

    editar_usuario(idUsuario, payload, options) {
        return axios.post(baseURL + '/usuarios/' + idUsuario, payload);        
    }

    compilar_usuarios() {
        return axios.get(baseURL + '/usuarios/recompilar');
    }

    listar_tokens(options) {
        return axios.get(utils.url_options(baseURL + '/tokens', options));
    }

    listar_tokens_roles(idToken) {
        return axios.get(baseURL + '/tokens/' + idToken + '/roles');
    }

    crear_token(payload) {
        return axios.post(baseURL + '/tokens', payload);
    }

    editar_token(idToken, payload) {
        return axios.put(baseURL + '/tokens/' + idToken, payload);
    }

    asignar_roles_token(idToken, payload) {
        return axios.put(baseURL + '/tokens/' + idToken + '/roles', payload);
    }

    desasignar_roles_token(idToken, payload) {
        return axios.delete(baseURL + '/tokens/' + idToken + '/roles', { data: payload });
    }

    listar_sesiones(options) {
        return axios.get(utils.url_options(baseURL + '/sesiones', options));
    }

    deshabilitar_sesiones(idSesion, payload) {
        return axios.put(baseURL + '/sesiones/' + idSesion, payload);
    }

    crear_contacto_usuario(usuario_id, payload) {
        return axios.post(baseURL+'/usuarios/'+usuario_id+'/contactos', payload);
    }

    editar_contacto_usuario(usuario_id, contacto_id, payload) {
        return axios.put(baseURL+'/usuarios/'+usuario_id+'/contactos/'+contacto_id, payload);
    }

    eliminar_contacto(usuario_id, contacto_id) {
        return axios.delete(baseURL+'/usuarios/'+usuario_id+'/contactos/'+contacto_id);
    }

    enviar_otp(payload) {
        return axios.post(baseURL+'/otp',payload);
    }

    validar_otp(payload) {
        return axios.post(baseURL+'/otp/validar', payload);
    }

    cargar_avatar(id) {
        store.commit('set',{spinner: false});
        return axios.get(baseURL+'/usuarios/'+id+'/avatar',null,{
            responseType: 'arraybuffer'
        });
    }
}

export default new Account();